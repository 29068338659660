import React from "react";
import { buildClasses } from "@nodeme/jss-react";
import classNames from "classnames";
const style = (theme) => (definitions) => {
    const defaults = definitions.nodeMeForms;
    return {
        radioLabel: defaults.radioLabel,
        radio: defaults.radio,
        radioIcon: defaults.radioIcon,
        radioPosition: defaults.radioPosition,
        radioIconSelected: defaults.radioIconSelected,
        hasError: defaults.hasError,
        checkboxIndicator: {
            border: `3px solid ${theme.palette.get("formBackground")}`,
            boxSizing: "border-box"
        },
        checkboxIndicatorSelected: {
            border: `3px solid ${theme.palette.get("primary")}`,
            backgroundColor: `${theme.palette.get("primary")}`,
            "&:after": {
                content: '""',
                border: "solid #fff",
                borderWidth: "0 3px 3px 0",
                transform: "rotate(45deg)",
                width: "7px",
                height: "12px",
                position: "absolute",
                marginLeft: "2px",
                marginTop: "-2px"
            }
        }
    };
};
const Checkbox = props => {
    const { override = {}, value, label, onChange, hasError } = props;
    const classes = buildClasses(style);
    return (React.createElement("label", { className: classNames([classes.radioLabel, override && override.label]) },
        label,
        React.createElement("input", { className: classNames([
                classes.radio,
                classes.radioPosition,
                hasError && classes.inputError
            ]), type: "checkbox", checked: value, onChange: e => onChange(e.target.checked || false) }),
        React.createElement("span", { className: classNames([
                classes.checkboxIndicator,
                classes.radioPosition,
                value && classes.checkboxIndicatorSelected
            ]) },
            React.createElement("span", null))));
};
export default Checkbox;
