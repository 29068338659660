const percentages = [
  0.95,
  0.9,
  0.85,
  0.8,
  0.75,
  0.7,
  0.65,
  0.6,
  0.55,
  0.5,
  0.45,
  0.4,
];

export function getPrice(price: number, lot: number) {
  const date = new Date();
  let result = { price: price * 0.8, percentage: "20" };
  const currentDay = date.getUTCDate();
  const active = lot === currentDay && date.getMonth() === 11;
  const houre = date.getUTCHours();
  if (active) {
    if (houre < 8) result = { price: price, percentage: "0" };
    else if (houre < 20)
      result = {
        price: price * percentages[houre - 8],
        percentage: ((1 - percentages[houre - 8]) * 100).toFixed(),
      };
  }
  return result;
}
