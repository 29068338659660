import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import NodeMeJss from "@nodeme/jss-react";

NodeMeJss.init({
  palette: {
    primary: "#246cb5",
    secondary: "#2c689c",
    tertiary: "#333333",
    danger: "#c4171f",
    success: "#27782b",
    warning: "#e8db05",
    default: "#d9d9d9",
    body: "#000",
    grey: "#7d7d7d",
    black: "#3e3e3e",
    lightGrey: "#ececec",
    background: "#ececec",
    formBackground: "rgba(224, 224, 224, 0.6)",
    formBackgroundActive: "rgba(224, 224, 224, 1)",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
