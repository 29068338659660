import React from "react";
import { Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import Block from "./Shared/Block";

export interface IProps {}

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  subheading: {
    letterSpacing: "0.1rem",
    color: theme.palette.get("primary"),
    fontSize: "0.9rem",
    fontWeight: "700",
    textTransform: "uppercase",
    margin: 0,
  },
  text: {},
  lable: {
    color: theme.palette.get("grey"),
    marginRight: "4px",
  },
});

export default function Header(props: IProps) {
  const classes = buildClasses(styles, props);
  return (
    <Grid spacing={12} vertical>
      <Grid item xs={10} lg={5}>
        <Block>
          <h6 className={classes.subheading}>FORMAT</h6>
          <p>
            Beim Artcare Adventkalender warten hinter jedem Türchen 3 Kunstwerke
            auf ihre neue EigentümerIn. Die Werke können im Rahmen einer
            Online-Abwärtsauktion erworben werden. Die Auktionsseite ist unter
            der Internetadresse xmas.artcare.at aufrufbar.
          </p>
          <p>
            Im Gegensatz zu einer klassischen Auktion, bei der vom Auktionator
            der Preis erhöht wird, bis nur mehr ein Bieter verbleibt, wird bei
            der Abwärtsauktion der Preis so lange nach unten angepasst, bis der
            erste Interessent über den „Kaufen“- Button auf der Auktionsseite
            das Werk kauft. Im Falle der Online-Abwärtsauktion erfolgt die
            Preisanpassung systematisch auf der Auktionsseite.
          </p>
          <p>
            Mit dem ersten Gebot ist ein Werk zu dem Preis, der zum Zeitpunkt
            des Gebotes gültig ist, verkauft und der Vertrag mit dem Bieter
            kommt zustande, sobald das Gebot verifiziert ist.
          </p>
          <p>
            Je länger also ein Interessent mit seinem Gebot wartet, desto
            niedriger wird der Preis. Allerdings steigt mit zunehmender Laufzeit
            auch die Wahrscheinlichkeit, dass ein anderer Interessent bietet und
            dann das Werk nicht mehr verfügbar ist. Es kann daher bei einer
            Abwärtsauktion sehr schnell zum Verkauf kommen.{" "}
          </p>
          <a target="__blank" href="/docs/Auktionsbedingungen.pdf">
            Auktionsbedingungen
          </a>
        </Block>
      </Grid>
      <Grid item xs={10} lg={5}>
        <Block>
          <h6 className={classes.subheading}>RECHT UND DATENSCHUTZ</h6>
          <p>
            Alle Rechte vorbehalten. Das Herunterladen, Drucken und Speichern
            von Dateien dieser Website ist für den ausschließlich privaten
            Gebrauch gestattet. Jede darüber hinaus gehende Nutzung, insb. das
            Legen eines Hyperlinks beziehungsweise das Framing auf oder
            hinsichtlich auch nur von Teilen dieser Website bedarf der
            vorherigen schriftlichen Zustimmung der ARTCARE Marketing und
            Vertriebs GmbH.
            <br />
            Jeglicher Inhalt der Website und ihrer Teile wurde unter Anwendung
            größtmöglicher Sorgfalt erstellt und überwacht. Jegliche Haftung
            bezüglich Links zu anderen Webseiten und der Richtigkeit und
            Aktualität der enthaltenen Angaben sowie für Datenverlust oder
            andere technische Beeinträchtigungen, die beim Betrachten oder
            Herunterladen von Daten dieser Website entstehen können, ist
            ausgeschlossen.
          </p>
          <p>
            Durch die Eintragung im Bieterformular werden Daten (Name und Email)
            übertragen, die auf einem Artcare-Server abgelegt werden. Damit
            werden von den eingetragenen Besuchern Zusendungen per E-Mail durch
            die Artcare Marketing und Vertriebs GmbH akzeptiert. Die Zustimmung
            kann jederzeit schriftlich (z.B. via E-mail an office@artcare.at)
            widerrufen werden. Die Daten werden nicht an Dritte weitergegeben.
            Es gilt österreichisches Recht bei Zuständigkeit des Handelsgerichts
            Wien.
          </p>
        </Block>
      </Grid>
      <Grid item xs={10} lg={5}>
        <Block>
          <h6 className={classes.subheading}>Kontakt</h6>
          <p>
            ARTCARE Marketing und Vertriebs GmbH
            <br />
            Rechte Bahngasse 30-32, 1030 Wien
            <br />
            <span className={classes.lable}>Tel.:</span> +43/681 105 42 464
            <br />
            <span className={classes.lable}>E-Mail:</span> office@artcare.at
            <br />
            <span className={classes.lable}>Firmenbuchnummer:</span> FN 287030d
            Og HG Wien
            <br />
            <span className={classes.lable}>UID Nummer:</span> ATU 74758924
          </p>
        </Block>
      </Grid>
    </Grid>
  );
}
