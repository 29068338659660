import React, { useState } from "react";
import Block from "./Shared/Block";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import { faSnowflake } from "@fortawesome/pro-light-svg-icons/faSnowflake";
import { Form } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import ArtworkBid from "./ArtworkBid";
import ArtworkInterest from "./ArtworkInterest";
import ArtworkSelling from "./ArtworkSelling";
import Axios from "axios";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faExclamation } from "@fortawesome/pro-light-svg-icons";
import Price from "./Price";
import { getPrice } from "../container/Price";

export interface IProps {
  code?: string;
  confirmation?: true;
  id: number;
  artist?: string;
  title?: string;
  mockup?: string | null;
  thumb?: string | null;
  lot?: number;
  description?: string;
  category?: string;
  technique?: string;
  artistDescription?: string;
  width?: number;
  height?: number;
  depth?: number;
  price?: number;
  year?: number;
  bid?: string;
  available?: number;
  isActiveDay: boolean;
  onClose: () => void;
}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  wrapper: {
    position: "relative",
  },
  lot: {
    color: theme.palette.get("primary"),
    padding: "0px 8px 0px 0px",
    fontSize: "3rem",
    lineHeight: "2rem",
    fontWeight: 600,
  },
  lineHolder: {
    position: "relative",
  },
  line: {
    position: "absolute",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
  },
  artist: {
    top: "5px",
  },
  title: {
    top: "32px",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  img: {
    maxWidth: "100%",
  },
  pendingMockup: {
    height: "150px",
    textAlign: "center",
    color: theme.palette.get("primary"),
    padding: "20px 0px",
    boxSizing: "border-box",
    fontSize: "4rem",
  },
  subHeading: {
    color: theme.palette.get("primary"),
    margin: "0px 0px 8px 0px",
  },
  data: {
    color: theme.palette.get("grey"),
    fontWeight: 100,
  },
  price: {
    fontWeight: 400,
  },
  bidContainer: { textAlign: "center" },
  info: {
    display: "block",
    textAlign: "right",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
  },
  badge: {
    color: "#fff",
    padding: "0px 8px",
    borderRadius: "4px",
    fontSize: "1.2rem",
  },
  primary: {
    backgroundColor: theme.palette.get("primary"),
  },
  danger: {
    backgroundColor: theme.palette.get("danger"),
  },
  success: {
    backgroundColor: theme.palette.get("success"),
  },
  confirmButtonHolder: {
    textAlign: "right",
  },
  confirmButton: {
    backgroundColor: theme.palette.get("primary"),
    border: "none",
    outline: "none",
    color: "#fff",
    fontSize: "2rem",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  spinner: {
    fontSize: "4rem",
    color: theme.palette.get("primary"),
    margin: "24px auto",
    display: "block",
  },
  successIcon: {
    fontSize: "4rem",
    color: "#fff",
    backgroundColor: theme.palette.get("success"),
    padding: "16px 16px",
    borderRadius: "100%",
    margin: "0px",
    display: "block",
  },
  errorIcon: {
    fontSize: "4rem",
    color: "#fff",
    backgroundColor: theme.palette.get("danger"),
    padding: "16px 36px",
    borderRadius: "100%",
    margin: "0px",
    display: "block",
  },
  infoText: {
    fontSize: "0.8rem",
    color: "#000",
    marginTop: "0px",
  },
});

async function confirm(
  id: number,
  code: string,
  setState: (state: string) => void,
  displayedValue: string
) {
  try {
    setState("pending");
    const result = await Axios.post(
      "https://artware2.datenbanker.at/api/confirmation",
      {
        artwork: id,
        code,
        displayedValue,
      }
    );

    if (result.data.error === false) setState("success");
    else if (result.data.status === "sold") setState("sold");
    else setState("error");
  } catch (error) {
    setState("error");
  }
}

export default function ArtworkDetails(props: IProps) {
  const classes = buildClasses(style, { ...props });
  const [bidding, setBidding] = useState(false);
  const [interest, setInterest] = useState(false);
  const [selling, setSelling] = useState(false);
  const [state, setState] = useState("ok");
  const price =
    typeof props.price === "string" ? parseFloat(props.price) : props.price;

  const confirmation = props.confirmation;

  return (
    <Block>
      <div className={classes.wrapper}>
        <Grid
          spacing={16}
          vertical
          root={{ top: 0, left: 0, right: 0, bottom: 0 }}
          alignItems="flex-end"
        >
          {props.mockup !== null && (
            <Grid item lg={2}>
              {typeof props.mockup === "undefined" && (
                <div className={classes.pendingMockup}>
                  <FontAwesomeIcon icon={faSpinnerThird} spin />
                </div>
              )}
              {Boolean(props.mockup) && (
                <img className={classes.img} alt="mockup" src={props.mockup} />
              )}
            </Grid>
          )}
          <Grid item lg={"auto"}>
            {props.thumb !== null && (
              <img alt="thumb" className={classes.img} src={props.thumb} />
            )}
          </Grid>
          <Grid item lg="rest-fixed">
            <div>
              <Grid root={{ bottom: 0 }}>
                <Grid item xs="auto">
                  <span className={classes.lot}>
                    <FontAwesomeIcon icon={faSnowflake} />
                  </span>
                </Grid>
                <Grid item xs="rest-fixed">
                  <Grid>
                    <Grid item>
                      <div className={classes.lineHolder}>
                        <span
                          className={classNames([classes.line, classes.artist])}
                        >
                          {props.artist}
                        </span>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={classes.lineHolder}>
                        <span
                          className={classNames([classes.line, classes.title])}
                        >
                          {props.title}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid spacing={16} vertical root={{ bottom: 0, left: 0, right: 0 }}>
              <Grid item lg={10 / 3}>
                <span className={classes.data}>
                  {props.width}x{props.height}x{props.depth}cm
                </span>
              </Grid>
              <Grid item xs={5} lg={10 / 3}>
                <span className={classes.data}>{props.category}</span>
              </Grid>
              <Grid item xs={5} lg={10 / 3}>
                <span className={classes.data}>{props.technique}</span>
              </Grid>
              {props.available === 0 && (
                <Grid item xs={10}>
                  <span className={classNames([classes.badge, classes.danger])}>
                    {confirmation && "Bereits "}Verkauft
                  </span>
                </Grid>
              )}
              {(props.available || 0) > 0 && (
                <Grid item>
                  <Price
                    badged
                    price={price || 0}
                    lot={props.lot || 0}
                    available={props.available || 0}
                  />
                </Grid>
              )}
              {state === "ok" && confirmation && (props.available || 0) > 0 && (
                <Grid item>
                  <p>
                    Sie bestätigen, dass sie dieses Werk zu oben angeführten
                    Preis kaufen möchten.
                  </p>
                  <div className={classes.confirmButtonHolder}>
                    <button
                      className={classes.confirmButton}
                      onClick={(e) => {
                        e.preventDefault();
                        const displayedValue = getPrice(
                          price || 0,
                          props.lot || 0
                        );
                        confirm(
                          props.id,
                          props.code || "",
                          setState,
                          displayedValue.price +
                            "€ " +
                            displayedValue.percentage +
                            "% off " +
                            price +
                            "€"
                        );
                      }}
                    >
                      Bestätigen & Kaufen
                    </button>
                  </div>
                </Grid>
              )}
              {state === "pending" && confirmation && (
                <Grid item>
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    className={classes.spinner}
                    spin
                  />
                </Grid>
              )}
              {state === "success" && confirmation && (
                <Grid item>
                  <Grid
                    spacing={16}
                    vertical
                    root={{ left: 0, right: 0, bottom: 0 }}
                  >
                    <Grid item xs={"112px"} xl="112px">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={classes.successIcon}
                      />
                    </Grid>
                    <Grid item xs="calc(100% - 112px)">
                      <h2 style={{ margin: 0 }}>Herzlichen Dank!</h2>
                      <p className={classes.infoText}>
                        Ein Mitarbeiter der Artcare meldet sich bei Ihnen in
                        kürze.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {state === "sold" && confirmation && (
                <Grid item>
                  <Grid
                    spacing={16}
                    vertical
                    root={{ left: 0, right: 0, bottom: 0 }}
                  >
                    <Grid item xs={"112px"}>
                      <FontAwesomeIcon
                        icon={faExclamation}
                        className={classes.errorIcon}
                      />
                    </Grid>
                    <Grid item xs="calc(100% - 112px)">
                      <h2 style={{ margin: 0 }}>Bereits Verkauft!</h2>
                      <p className={classes.infoText}>
                        Es tut uns Leid, jemand anderer war schneller.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {state === "error" && confirmation && (
                <Grid item>
                  <Grid
                    spacing={16}
                    vertical
                    root={{ left: 0, right: 0, bottom: 0 }}
                  >
                    <Grid item xs={"112px"}>
                      <FontAwesomeIcon
                        icon={faExclamation}
                        className={classes.errorIcon}
                      />
                    </Grid>
                    <Grid item xs="calc(100% - 112px)">
                      <h2 style={{ margin: 0 }}>Fehler!</h2>
                      <p className={classes.infoText}>
                        Es tut uns Leid, ein unbekannter Fehler ist aufgetreten
                        bitte kontaktieren Sie uns direkt unter{" "}
                        <a href="mailTo:office@artcare.at">office@artcare.at</a>
                        .
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {confirmation && props.available === 0 && (
                <Grid item>
                  <p style={{ marginBottom: "3px" }}>
                    Es tut uns leid, dass Werk wurde bereits verkauft.
                  </p>
                </Grid>
              )}
              {!confirmation && !bidding && !interest && !selling && (
                <Grid>
                  <h6 className={classes.subHeading}>ÜBER DAS WERK</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.description || "",
                    }}
                  />
                </Grid>
              )}
              {!confirmation && !bidding && !interest && !selling && (
                <Grid>
                  <h6 className={classes.subHeading}>ÜBER DEN KÜNSTLER/IN</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.artistDescription || "",
                    }}
                  />
                </Grid>
              )}
              {bidding && (
                <ArtworkBid
                  id={props.id}
                  lot={props.lot || 0}
                  onClose={() => {
                    setBidding(false);
                  }}
                />
              )}
              {interest && (
                <ArtworkInterest
                  lot={props.lot || 0}
                  onClose={() => {
                    setInterest(false);
                  }}
                />
              )}
              {selling && (
                <ArtworkSelling
                  lot={props.lot || 0}
                  onClose={() => {
                    setSelling(false);
                  }}
                />
              )}
            </Grid>
            {!confirmation && !bidding && !interest && !selling && (
              <Form onSubmit={() => {}}>
                <Grid
                  spacing={16}
                  vertical
                  root={{ left: 0, right: 0, bottom: 0 }}
                  justify="flex-end"
                >
                  <Button item xs="120px" onClick={props.onClose}>
                    Schließen
                  </Button>
                  {(props.available || 0) > 0 && (
                    <Button
                      item
                      xs="120px"
                      success
                      onClick={(e) => {
                        e.preventDefault();
                        setBidding(true);
                      }}
                    >
                      Kaufen
                    </Button>
                  )}
                </Grid>
              </Form>
            )}
          </Grid>
        </Grid>
      </div>
    </Block>
  );
}
