import React from "react";
import { buildClasses } from "@nodeme/jss-react";
import classnames from "classnames";
const styles = (theme) => (definitions) => ({
    container: {
        width: "100%",
        paddingLeft: (props) => props.spacing || 8,
        paddingRight: (props) => props.spacing || 8,
        marginRight: "auto",
        marginLeft: "auto",
        boxSizing: "border-box"
    },
    static: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "540px"
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: "720px"
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "960px"
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: "1140px"
        }
    }
});
const Container = ({ spacing, notFluid, children }) => {
    const classes = buildClasses(styles, { spacing });
    return (React.createElement("div", { className: classnames([classes.container, notFluid && classes.static]) }, children));
};
export default Container;
