import Error from "./Errors";
import theme from "./Theme";
import defaultPalette from "./Defaults/palette";
import defaultBreakpoints from "./Defaults/breakPoints";
export default class NodeMeJss {
    constructor() {
        this.inited = false;
        this.definitions = {};
        this.palette = {};
        this.breakpoints = {};
        this.init = this.init.bind(this);
        this.isInited = this.isInited.bind(this);
        this.getDefinitions = this.getDefinitions.bind(this);
        this.setDefinitions = this.setDefinitions.bind(this);
        this.getBreakpoints = this.getBreakpoints.bind(this);
        this.getPalette = this.getPalette.bind(this);
    }
    init(settings, definitions = {}) {
        if (this.inited)
            throw new Error("InitError", "Lib @nodeme/jss-react has already been inited!");
        this.inited = true;
        this.palette = {
            ...defaultPalette,
            ...(settings.palette || {})
        };
        this.breakpoints = {
            ...defaultBreakpoints,
            ...(settings.breakpoints || {})
        };
        this.setDefinitions(definitions);
    }
    isInited() {
        return this.inited;
    }
    getDefinitions() {
        return this.definitions;
    }
    setDefinitions(definitions) {
        if (typeof definitions === "function")
            this.definitions = { ...this.definitions, ...(definitions(theme) || {}) };
        else
            this.definitions = { ...this.definitions, ...(definitions || {}) };
    }
    getPalette() {
        return this.palette;
    }
    getBreakpoints() {
        return this.breakpoints;
    }
}
