import React, { useEffect, useState } from "react";
import { Grid } from "@nodeme/grid-react";
import { withRouter, RouteProps } from "react-router-dom";
import Artwork from "./Artwork";
import { categories } from "./Calendar";

export interface IProps extends RouteProps {}

interface IArtwork {
  pending: boolean;
  error: boolean;
  data: null | {
    id: number;
    category?: string;
    technique?: string;
    artist?: string;
    title?: string;
    artistDescription?: string;
    description?: string;
    width?: number;
    height?: number;
    depth?: number;
    price?: number;
    thumbExtension?: string;
    thumbHeight?: number;
    thumbId?: number;
    thumbName?: string;
    thumbUpdateDate?: string;
    thumbWidth?: number;
    active: boolean;
    year?: number;
    lot?: number;
    bid?: string;
    available?: number;
  };
}

async function loadArtwork(
  id: string,
  setArtwork: (artwork: IArtwork) => void
) {
  const url = `https://artware2.datenbanker.at/api/artwork/${id}/public`;
  const response = await fetch(url).then((response) => response.json());
  const item = response.artwork;
  const data = {
    id: item.data.id,
    artist: item.data.name,
    title: item.data.title,
    category: categories[item.data.category - 1],
    technique: item.data.techniqueName,
    artistDescription: item.data.artistDescription,
    description: (item.data.description || "").split(" #")[1] || "",
    active: false,
    width:
      typeof item.data.width === "string" || typeof item.data.width === "number"
        ? parseInt(item.data.width)
        : undefined,
    height:
      typeof item.data.height === "string" ||
      typeof item.data.height === "number"
        ? parseInt(item.data.height)
        : undefined,
    depth:
      typeof item.data.depth === "string" || typeof item.data.depth === "number"
        ? parseInt(item.data.depth)
        : undefined,
    price: item.data.sellingPrice,
    thumbExtension: item.data.originalThumbExtension,
    thumbHeight: item.data.originalThumbHeight,
    thumbId: item.data.originalThumbId,
    thumbName: item.data.originalThumbName,
    thumbUpdateDate: item.data.originalThumbUpdateDate,
    thumbWidth: item.data.originalThumbWidth,
    year: item.data.year,
    bid: item.data.rentalPrice,
    lot: parseInt((item.data.description || "").split(" #")[0]) || 0,
    available: item.data.available,
  };
  setArtwork({ pending: false, error: false, data });
}

export default withRouter(function Header(props: IProps) {
  const params = new URLSearchParams(props.location?.search);
  //const classes = buildClasses(styles, props);
  const [artwork, setArtwork] = useState<IArtwork>({
    error: false,
    pending: false,
    data: null,
  });
  useEffect(() => {
    if (!artwork.pending && artwork.data === null) {
      loadArtwork(params.get("id") || "", setArtwork);
    }
  }, [artwork.pending, artwork.data, params]);
  const { data } = artwork;
  return (
    <Grid spacing={12} vertical>
      <Grid item>
        {data !== null && (
          <Artwork
            confirmation
            code={params.get("code") || ""}
            available={data.available}
            active={true}
            id={data.id}
            lot={data.lot}
            artist={data.artist}
            title={data.title}
            category={data.category}
            technique={data.technique}
            width={data.width}
            height={data.height}
            depth={data.depth}
            thumbExtension={data.thumbExtension}
            thumbHeight={data.thumbHeight}
            thumbWidth={data.thumbWidth}
            thumbId={data.thumbId}
            thumbName={data.thumbName}
            thumbUpdateDate={data.thumbUpdateDate}
            description={data.description}
            artistDescription={data.artistDescription}
            year={data.year}
            price={data.price}
            bid={data.bid}
            toggle={() => {}}
          />
        )}
      </Grid>
    </Grid>
  );
});
