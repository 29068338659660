import React from "react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import Block from "./Block";

export interface IProps {
  text?: string;
}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  pendingIcon: {
    fontSize: "4rem",
    color: theme.palette.get("primary"),
  },
  pendingBlock: {
    textAlign: "center",
    padding: "36px 0px",
  },
  pendingText: {
    color: theme.palette.get("grey"),
    fontWeight: 100,
  },
});

export default function PendingBlock(props: IProps) {
  const classes = buildClasses(style, props);
  return (
    <Grid spacing={12} vertical justify="center" root={{ top: 64 }}>
      <Grid item md="300px">
        <Block>
          <div className={classes.pendingBlock}>
            <FontAwesomeIcon
              className={classes.pendingIcon}
              icon={faSpinnerThird}
              spin
            />
            <p className={classes.pendingText}>{props.text}</p>
          </div>
        </Block>
      </Grid>
    </Grid>
  );
}
