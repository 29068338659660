import React, { useEffect, useState } from "react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import classNames from "classnames";
import { getPrice } from "../container/Price";

export interface IProps {
  badged?: boolean;
  price: number;
  lot: number;
  available: number;
}

interface IReduced {
  price: number;
  percentage: string;
}

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  holder: {
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  oldPrice: {
    textDecoration: "line-through",
    display: "inline-block",
    marginRight: "4px",
  },
  price: {
    fontSize: "1rem",
    color: theme.palette.get("success"),
  },
  badge: {
    color: "#fff",
    padding: "0px 8px",
    borderRadius: "4px",
    fontSize: "1.2rem",
    fontWeight: 400,
  },
  badgeSmall: {
    color: "#fff",
    padding: "0px 8px",
    borderRadius: "4px",
    fontSize: "0.8rem",
    fontWeight: 400,
  },
  success: {
    backgroundColor: theme.palette.get("success"),
  },
  danger: {
    backgroundColor: theme.palette.get("danger"),
  },
  off: {
    display: "inline-block",
    margin: "0px 8px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 8px",
    },
  },
});

function handlePrice(
  price: number,
  lot: number,
  setPrice: (reduced: IReduced, newTimer: any) => void
) {
  const date = new Date();
  const nextHoure = Date.UTC(2020, 10, 1, date.getUTCHours() + 1, 0, 0);
  const currentHoure = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    1,
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  const timer = window.setTimeout(() => {
    handlePrice(price, lot, setPrice);
  }, nextHoure - currentHoure);
  setPrice(getPrice(price, lot), timer);
  return timer;
}

export default function Header(props: IProps) {
  const classes = buildClasses(styles, { ...props });
  const price = props.price;

  const [reduced, setReduced] = useState<null | IReduced>(null);

  useEffect(() => {
    let timer: any;
    if (reduced === null)
      timer = handlePrice(
        price,
        props.lot,
        (reduced: IReduced, newTimer: any) => {
          setReduced(reduced);
          timer = newTimer;
        }
      );
    return () => {
      window.clearTimeout(timer);
    };
  }, [price, props.lot]);

  return (
    <div className={classes.holder}>
      {reduced !== null && props.available !== 0 && (
        <span>
          <span
            className={classNames([
              (props.badged && classes.badge) || classes.price,
              props.badged && classes.success,
            ])}
          >
            {reduced.price.toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
          <span className={classes.off}>{reduced.percentage}% off</span>
          <span className={classes.oldPrice}>
            {price.toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
        </span>
      )}
      {props.available === 0 && (
        <span
          className={classNames([
            (props.badged && classes.badge) || classes.badgeSmall,
            classes.danger,
          ])}
        >
          Verkauft
        </span>
      )}
    </div>
  );
}
