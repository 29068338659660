import React, { Children } from "react";
import { Grid } from "@nodeme/grid-react";
import NodeMeJss from "@nodeme/jss-react";
import defaultStyles from "../Defaults/styles";
export default class Form extends React.Component {
    constructor(props) {
        super(props);
        NodeMeJss.setDefinitions(defaultStyles);
    }
    renderChildren(children, spacing, parts, root) {
        const rows = [];
        let rowToBuild = []; //works as a buffer for unfinished rows
        Children.forEach(children, (child, index) => {
            //if type is a grid element build rowBuffer (roToBuild) and child after it
            if (child !== null && child.type === Grid) {
                if (rowToBuild.length > 0)
                    rows.push(React.createElement(Grid, { key: index, spacing: spacing, parts: parts, root: root, vertical: true }, rowToBuild));
                rowToBuild = [];
                rows.push(child);
            }
            //if last element add to rowBuffer (row to build) and build it
            else if (index === Children.toArray(children).length - 1) {
                if (Boolean(child))
                    rowToBuild.push(child);
                if (rowToBuild.length)
                    rows.push(React.createElement(Grid, { key: index, spacing: spacing, parts: parts, root: root, vertical: true }, rowToBuild));
                rowToBuild = [];
            }
            //append to rowBuffer
            else if (Boolean(child)) {
                rowToBuild.push(child);
            }
        });
        return rows;
    }
    render() {
        const { spacing, children, parts, root, onSubmit = () => { } } = this.props;
        return (React.createElement("form", { onSubmit: onSubmit }, this.renderChildren(children, spacing, parts, root)));
    }
}
