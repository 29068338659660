import config from "./";
import Error from "./Errors";
const getBreakpoint = (name) => {
    const breakPoint = config.getBreakpoints()[name];
    if (!breakPoint) {
        throw new Error("NotSetError", `Breakpoint ${name} has not been defined!`);
    }
    return breakPoint;
};
const getColor = (name) => {
    const color = config.getPalette()[name];
    if (!color) {
        throw new Error("NotSetError", `Color ${name} has not been defined in palette!`);
    }
    return color;
};
const theme = {
    breakpoints: {
        up: (name) => {
            return `@media (min-width: ${getBreakpoint(name)})`;
        },
        down: (name) => {
            return `@media (max-width: ${getBreakpoint(name)})`;
        }
    },
    palette: {
        get: getColor
    }
};
export default theme;
