export default {
    primary: "#5c90d2",
    secondary: "#2c689c",
    tertiary: "#333333",
    danger: "#b7433f",
    success: "#559554",
    warning: "#e8db05",
    default: "#ececec",
    body: "#ececec",
    grey: "#e0e0e0",
    background: "#ececec",
    formBackground: "rgba(224, 224, 224, 0.6)",
    formBackgroundActive: "rgba(224, 224, 224, 1)"
};
