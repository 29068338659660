import { Container } from "@nodeme/grid-react";
import React from "react";
import "./assets/styles/App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Calendar from "./components/Calendar";
import Impressum from "./components/Impressum";
import Confirm from "./components/Confirm";

function App() {
  return (
    <div className="App">
      <Router>
        <Header>
          <Navigation />
        </Header>
        <main>
          <div>
            <Container notFluid>
              <Switch>
                <Route path="/" exact>
                  <Calendar />
                </Route>
                <Route path="/confirmation" exact>
                  <Confirm />
                </Route>
                <Route path="/impressum" exact>
                  <Impressum />
                </Route>
              </Switch>
            </Container>
          </div>
        </main>
      </Router>
    </div>
  );
}

export default App;
